/* Dropdown container */
.dropdown-container {
    position: relative;
    display: inline-block;
}

/* Dropdown button */
.dropdown-toggle {
    cursor: pointer;
}

/* Dropdown menu */
.dropdown-menu {
    position: absolute;
    top: 100%; /* Positions the menu directly below the button */
    left: 0;
    z-index: 10;
    width: 200px;
    background-color: rgb(248 250 252);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Ensure the dropdown stays visible on hover */
.dropdown-container:hover .dropdown-menu,
.dropdown-menu:hover {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.gray-image{
    filter:grayscale(1);
}

/* .navbar_video{
    position: absolute;
    width: 100%;
} */

@keyframes slide-up {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animate-slide-up {
    animation: slide-up 0.40s ease-in-out forwards;
  }
  
  